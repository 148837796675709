import React from 'react'
import AdminTemplate from '../../templates/admin-template'
import DashboardCards from '../../components/admin/dashboard/dashboard-cards'

const DashboardPage = () => {
  return (
    <AdminTemplate>
        <DashboardCards />
    </AdminTemplate>
  )
}

export default DashboardPage