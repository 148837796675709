import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Button, Col, Form, Row } from "react-bootstrap";
import { getCurrentDate } from "../../../helpers/functions/date-time";
import { getManagerStoreOrdersWithDate } from "../../../api/manager-store-service";
import { toast } from "../../../helpers/swal";

const ordersColumns = [
  {
    name: "Index",
    selector: (row, index) => index + 1,
    sortable: "true",
  },
  {
    name: (
      <div className="text-center">
        <div className="text-center">
          Order
          <br />
          Date
        </div>
      </div>
    ),
    selector: (row) => row.order_date,
    sortable: "true",
    center: "true",
  },
  {
    name: (
      <div className="text-center">
        <div className="text-center">
          Total
          <br />
          Orders
        </div>
      </div>
    ),
    selector: (row) => row.total_orders,
    sortable: "true",
    center: "true",
  },
  {
    name: (
      <div className="text-center">
        <div className="text-center">
          Total
          <br />
          Products
        </div>
      </div>
    ),
    selector: (row) => row.total_products,
    sortable: "true",
    center: "true",
  },
  {
    name: (
      <div className="text-center">
        <div className="text-center">
          Total
          <br />
          Shipping
          <br />
          Cost
        </div>
      </div>
    ),
    selector: (row) => row.total_shipping,
    sortable: "true",
    center: "true",
  },
  {
    name: (
      <div className="text-center">
        <div className="text-center">
          Total
          <br />
          Cost
        </div>
      </div>
    ),
    selector: (row) => row.total_cost,
    sortable: "true",
    center: "true",
  },
];

const ordersTotalColumns = [
  {
    name: (
      <div className="text-center">
        <div className="text-center">
          Total
          <br />
          Orders
        </div>
      </div>
    ),
    selector: (row) => row.total_orders,
    center: "true",
  },
  {
    name: (
      <div className="text-center">
        <div className="text-center">
          Total
          <br />
          Product
          <br />
          Cost
        </div>
      </div>
    ),
    selector: (row) => row.total_urun,
    center: "true",
  },
  {
    name: (
      <div className="text-center">
        <div className="text-center">
          Total
          <br />
          Shipping
          <br />
          Cost
        </div>
      </div>
    ),
    selector: (row) => row.total_kargo,
    center: "true",
  },
  {
    name: (
      <div className="text-center">
        <div className="text-center">
          Total
          <br />
          Order
          <br />
          Cost
        </div>
      </div>
    ),
    selector: (row) => row.total_order_maliyet,
    center: "true",
  }
];

const Summary = () => {
  const [loading, setLoading] = useState(true);
  const [ordersList, setOrdersList] = useState([]);
  const [ordersTotal, setOrdersTotal] = useState([]);
  const [filters, setFilters] = useState({
    beginning_date: "",
    ending_date: "",
  });
  const [errors, setErrors] = useState({
    beginning_date: "",
    ending_date: "",
  });

  const loadData = async () => {
    try {
      const formData = new FormData();
      formData.append("beginning_date", filters.beginning_date);
      formData.append("ending_date", filters.ending_date);
      const resp = await getManagerStoreOrdersWithDate(formData);
      setOrdersList(resp.data.data);
      setOrdersTotal(resp.data.total_values);
    } catch (err) {
      const message = err.response ? err.response.data.message : err;
      toast(message, "error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line
  }, []);

  const handleFilterChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const handleChange = () => {
    const newErrors = {};
    if (!filters.beginning_date) {
      newErrors.beginning_date = "Beginning date is required.";
    }
    if (!filters.ending_date) {
      newErrors.ending_date = "Ending date is required.";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
    if(filters.beginning_date && filters.ending_date){
      loadData();
    }
  };

  return (
    <div>
      <Row>
        <Col sm={5}>
          <Form.Group className="mb-3">
            <Form.Label htmlFor="beginning_date" style={{ fontWeight: "600" }}>
              Beginning Date
            </Form.Label>
            <Form.Control
              type="date"
              name="beginning_date"
              id="beginning_date"
              max={
                filters.ending_date && getCurrentDate() > filters.ending_date
                  ? filters.ending_date
                  : getCurrentDate()
              }
              value={filters.beginning_date}
              onChange={handleFilterChange}
              style={{
                borderRadius: "0.3rem",
                boxShadow: "0px 0px 20px 0px rgba(255, 255, 255, 0.8)",
              }}
              required
              isInvalid={errors.beginning_date !== ""}
            />
            <Form.Control.Feedback type="invalid">
              {errors.beginning_date}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col sm={5}>
          <Form.Group className="mb-3">
            <Form.Label htmlFor="ending_date" style={{ fontWeight: "600" }}>
              Ending Date
            </Form.Label>
            <Form.Control
              type="date"
              min={filters.beginning_date}
              name="ending_date"
              id="ending_date"
              value={filters.ending_date}
              onChange={handleFilterChange}
              max={getCurrentDate()}
              style={{
                borderRadius: "0.3rem",
                boxShadow: "0px 0px 20px 0px rgba(255, 255, 255, 0.8)",
              }}
              required
              isInvalid={errors.ending_date !== ""}
            />
            <Form.Control.Feedback type="invalid">
              {errors.ending_date}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col
          sm={2}
          className="text-end d-flex align-items-center justify-content-end"
        >
          <Button
            type="button"
            style={{
              width: "100%",
              marginTop: "1rem",
              color: "white",
              fontWeight: "600",
              borderRadius: "0.3rem",
              boxShadow: "0px 0px 20px 0px rgba(255, 255, 255, 0.8)",
            }}
            onClick={handleChange}
            disabled={loading}
          >
            Search
          </Button>
        </Col>
      </Row>
      <DataTable
        title="Total"
        columns={ordersTotalColumns}
        data={ordersTotal}
        progressPending={loading}
        highlightOnHover
      />
      <DataTable
        title="Orders List"
        columns={ordersColumns}
        data={ordersList}
        progressPending={loading}
        highlightOnHover
        customStyles={{
          headCells: {
            style: {
              paddingLeft: "0px",
              paddingRight: "0px",
            },
          },
          cells: {
            style: {
              paddingLeft: "0",
              paddingRight: "0",
            },
          },
        }}
      />
    </div>
  );
};

export default Summary;
