import React from 'react'
import AdminTemplate from '../../templates/admin-template'
import IndoorStores from '../../components/admin/indoor-stores/indoor-stores'

const IndoorStoresPage = () => {
  return (
    <AdminTemplate>
        <IndoorStores />
    </AdminTemplate>
  )
}

export default IndoorStoresPage