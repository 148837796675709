import React, { useEffect, useState } from 'react'
import CustomRoutes from './router/custom-routes'
import { loginFailed, loginSuccess } from './store/slices/auth-slice';
import { useAppDispatch } from './store/hooks';
import { settings } from "./helpers/settings";
import { encryptedLocalStorage } from './helpers/functions/encrypt-storage';
import { getUser } from './api/user-service';

const App = () => {
  const [loading, setLoading] = useState(true);
  const dispatch = useAppDispatch();

  const loadData = async () => {
    try {
      const token = encryptedLocalStorage.getItem("token");
      if (token) {
        const resp = await getUser();
        dispatch(loginSuccess(resp.data.data));
      }
    } catch (err) {
      const message = err.response ? err.response.data.message : err;
      dispatch(loginFailed());
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    document.title = `${settings.siteName}`;
    loadData();
  }, []);

  return (
    <>
      <CustomRoutes />
    </>
  )
}

export default App