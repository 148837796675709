import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider as StoreProvider } from "react-redux";
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from "./store";
import "./assets/scss/styles.scss";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.Fragment>
  {/* <React.StrictMode> */}
    <StoreProvider store={store}>
      <App />
    </StoreProvider>
  {/* </React.StrictMode> */}
  </React.Fragment>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
