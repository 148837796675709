import React from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import avatar from "../../../assets/img/avatar/user-dummy-img.jpg";
import "./sidebar.scss";
import { question } from "../../../helpers/swal";
import { BiBookmark, BiChevronLeft } from "react-icons/bi";
import { AiOutlineHome, AiOutlineUser } from "react-icons/ai";
import { MdOutlineShoppingCart } from "react-icons/md";
import { BsShop, BsShopWindow } from "react-icons/bs";
import { HiOutlineAcademicCap } from "react-icons/hi";
import { RiMoneyDollarBoxLine } from "react-icons/ri";
import { LuFileBarChart2 } from "react-icons/lu";
import { RxMixerVertical } from "react-icons/rx";
import { FiLogOut } from "react-icons/fi";
import { chevronClicked } from "../../../store/slices/sidebar-slice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { FaUser, FaUserCircle } from "react-icons/fa";
import { logout } from "../../../store/slices/auth-slice";
import { encryptedLocalStorage } from "../../../helpers/functions/encrypt-storage";

const Sidebar = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { sidebarLeft } = useAppSelector((state) => state.left);
  const { isUserLogin, user } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  const handleSidebarToggle = () => {
    dispatch(chevronClicked(!sidebarLeft));
  };

  const handleLogout = () => {
    question("Logout", "Are you sure to logout?").then((result) => {
      if (result.isConfirmed) {
        dispatch(logout());
        encryptedLocalStorage.removeItem("token");
        localStorage.removeItem("userName");
        navigate("/");
      }
    });
  };

  const userName = localStorage.getItem("userName");

  return (
    <Navbar
      bg="light"
      expand="lg"
      className={sidebarLeft ? "admin-navbar admin-left" : "admin-navbar"}
      variant="dark"
    >
      <Container>
        {/* {isUserLogin && ( */}
        <>
          <div className="avatar">
            <h5>TXMAYA SHIPSTATION</h5>
            <div className="admin-brand">
              <Navbar.Brand>
                <img src={avatar} alt="avatar" className="img-fluid" />
              </Navbar.Brand>
              <p>{userName}</p>
            </div>
          </div>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link
                as={Link}
                to="/dashboard"
                active={pathname === "/dashboard"}
              >
                <AiOutlineHome /> Home
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/dashboard/profile"
                active={pathname.startsWith("/dashboard/profile")}
              >
                <AiOutlineUser /> Profile
              </Nav.Link>
              {user.profile_type == 3 && (
                <>
                  <Nav.Link
                    as={Link}
                    to="/dashboard/orders"
                    active={pathname.startsWith("/dashboard/orders")}
                  >
                    <MdOutlineShoppingCart /> Orders
                  </Nav.Link>
                  {/* <Nav.Link
                  as={Link}
                  to="/dashboard/stores"
                  active={pathname.startsWith("/dashboard/stores")}
                >
                  <BsShop /> Stores
                </Nav.Link> */}
                  <Nav.Link
                    as={Link}
                    to="/dashboard/all-stores-summary"
                    active={pathname.startsWith(
                      "/dashboard/all-stores-summary"
                    )}
                  >
                    <BsShopWindow />Summary
                  </Nav.Link>
                  <Nav.Link
                    as={Link}
                    to="/dashboard/indoor-stores"
                    active={pathname.startsWith("/dashboard/indoor-stores")}
                  >
                    <BsShop /> Indoor Stores
                  </Nav.Link>
                  <Nav.Link
                    as={Link}
                    to="/dashboard/outdoor-stores"
                    active={pathname.startsWith("/dashboard/outdoor-stores")}
                  >
                    <BsShopWindow /> Outdoor Stores
                  </Nav.Link>
                </>
              )}
              {user.profile_type == 2 && (
                <>
                  <Nav.Link
                    as={Link}
                    to="/dashboard/my-store-summary"
                    active={pathname.startsWith("/dashboard/my-store-summary")}
                  >
                    <BsShopWindow /> Store Summary
                  </Nav.Link>
                </>
              )}
              {/* <Nav.Link
              as={Link}
              to="/dashboard"
              active={pathname.startsWith("/dashboard/payment")}
            >
              <RiMoneyDollarBoxLine /> Payment
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="/dashboard"
              active={pathname.startsWith("/dashboard/report")}
            >
              <LuFileBarChart2 /> Report
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="/dashboard"
              active={pathname.startsWith("/dashboard/settings")}
            >
              <RxMixerVertical /> Settings
            </Nav.Link> */}
              <Nav.Link onClick={handleLogout}>
                Logout <FiLogOut />
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </>
        {/* )} */}
      </Container>
      <button
        className={`toggle-button ${sidebarLeft ? "active" : ""}`}
        onClick={handleSidebarToggle}
      >
        <div>
          <BiChevronLeft />
        </div>
      </button>
    </Navbar>
  );
};

export default Sidebar;

// import React from "react";
// import { Container, Nav, Navbar } from "react-bootstrap";
// import { Link, useLocation, useNavigate } from "react-router-dom";
// import { useAppDispatch } from "../../../store/hooks";
// import { logout } from "../../../store/slices/auth-slice";
// import logo from "../../../assets/img/logo/txmaya-200x200.png";
// // import { encryptedSessionStorage, encryptedLocalStorage } from "../../../helpers/functions/encrypt-storage";
// import "./sidebar.scss";

// const SideBar = () => {
//   const dispatch = useAppDispatch();
//   const navigate = useNavigate();
//   const { pathname } = useLocation();

//   const handleLogout = () => {
//     // question("Logout", "Are you sure to logout?").then((result) => {
//     //   if (result.isConfirmed) {
//         // dispatch(logout());
//         // encryptedLocalStorage.removeItem("token");
//         // encryptedSessionStorage.removeItem("token");
//         navigate("/");
//         window.location.reload();
//     //   }
//     // });
//   };

//   return (
//     <Navbar bg="primary" expand="lg" className="admin-navbar" variant="dark">
//       <Container>
//         <Navbar.Brand as={Link} to="/">
//           <img src={logo} alt="AdminPanelLogo" />
//         </Navbar.Brand>
//         <Navbar.Toggle aria-controls="basic-navbar-nav" />
//         <Navbar.Collapse id="basic-navbar-nav">
//           <Nav className="me-auto">
//             <Nav.Link as={Link} to="/dashboard" active={pathname === "/dashboard"}>
//               Dashboard
//             </Nav.Link>
//             <Nav.Link
//               as={Link}
//               to="/orders"
//               active={pathname === "/orders"}
//             >
//               Orders
//             </Nav.Link>
//             <Nav.Link
//               as={Link}
//               to="/stores"
//               active={pathname === "/stores"}
//             >
//               Stores
//             </Nav.Link>
//             {/* <Nav.Link
//               as={Link}
//               to="/admin/brands"
//               active={pathname === "/admin/brands"}
//             >
//               Brands
//             </Nav.Link>
//             <Nav.Link
//               as={Link}
//               to="/admin/users"
//               active={pathname === "/admin/users"}
//             >
//               Users
//             </Nav.Link>
//             <Nav.Link
//               as={Link}
//               to="/admin/reviews"
//               active={pathname === "/admin/rewiews"}
//             >
//               Reviews
//             </Nav.Link>
//             <Nav.Link
//               as={Link}
//               to="/admin/contact-messages"
//               active={pathname === "/admin/contact-messages"}
//             >
//               Contact Messages
//             </Nav.Link>
//             <Nav.Link
//               as={Link}
//               to="/admin/orders"
//               active={pathname === "/admin/orders"}
//             >
//               Orders
//             </Nav.Link>
//             <Nav.Link
//               as={Link}
//               to="/admin/coupons"
//               active={pathname === "/admin/reports"}
//             >
//               Coupons
//             </Nav.Link>
//             <Nav.Link
//               as={Link}
//               to="/admin/reports"
//               active={pathname === "/admin/reports"}
//             >
//               Reports
//             </Nav.Link> */}
//             <Nav.Link as={Link} to="/">
//               Web Site
//             </Nav.Link>
//             <Nav.Link onClick={handleLogout}> Logout</Nav.Link>
//           </Nav>
//         </Navbar.Collapse>
//       </Container>
//     </Navbar>
//   );
// };
// export default SideBar;
