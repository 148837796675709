import React from 'react'
import AnanymousTemplate from '../../templates/ananymous-template'
import SkuGenerator from '../../components/common/sku-generator/sku-generator'

const SkuGeneratorPage = () => {
  return (
    <AnanymousTemplate>
        <SkuGenerator/>
    </AnanymousTemplate>
  )
}

export default SkuGeneratorPage;