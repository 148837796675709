import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LoginPage from "../pages/common/login-page";
import RegisterPage from "../pages/common/register-page";
import DashboardPage from "../pages/admin/dashboard-page";
import OrdersPage from "../pages/admin/orders-page";
import StoresPage from "../pages/admin/stores-page";
import ProtectedRoute from "./protected-route";
import NotFoundPage from "../pages/common/not-found-page";
import SkuGeneratorPage from "../pages/common/sku-generator-page";
import IndoorStoresPage from "../pages/admin/indoor-stores-page";
import OutdoorStoresPage from "../pages/admin/outdoor-stores-page";
import IndoorStorePage from "../pages/admin/indoor-store-page";
import OutdoorStorePage from "../pages/admin/outdoor-store-page";
import ProfilePage from "../pages/common/profile-page";
import ManagerStoreSummaryPage from "../pages/manager/manager-store-summary-page";
import AllStoresSummaryPage from "../pages/admin/all-stores-summary-page";
import StoreSummaryPage from "../pages/admin/store-summary-page";

const CustomRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/">
          <Route index element={<LoginPage />} />
          <Route path="register" element={<RegisterPage />} />
          <Route path="sku-generator" element={<SkuGeneratorPage />} />
          <Route path="dashboard">
            <Route
              index
              element={
                // <ProtectedRoute admin={true}>
                  <DashboardPage />
                // </ProtectedRoute>
              }
            />
             <Route path="profile">
              <Route
                index
                element={
                  // <ProtectedRoute admin={true}>
                    < ProfilePage/>
                  // </ProtectedRoute>
                }
              />
            </Route>
            <Route
              path="orders"
              element={
                // <ProtectedRoute admin={true}>
                  <OrdersPage />
                // </ProtectedRoute>
              }
            />
            <Route
              path="stores"
              element={
                // <ProtectedRoute admin={true}>
                  <StoresPage />
                // </ProtectedRoute>
              }
            />
            <Route path="indoor-stores">
              <Route
                index
                element={
                  // <ProtectedRoute admin={true}>
                    <IndoorStoresPage />
                  // </ProtectedRoute>
                }
              />
              <Route path=":indoorStoresId" element={
              // <ProtectedRoute admin={true}>
                <IndoorStorePage/>
                // </ProtectedRoute>
              }/>
            </Route>
            <Route path="outdoor-stores">
              <Route
                index
                element={
                  // <ProtectedRoute admin={true}>
                    <OutdoorStoresPage />
                  // </ProtectedRoute>
                }
              />
              <Route path=":outdoorStoresId" element={
              // <ProtectedRoute admin={true}>
                <OutdoorStorePage/>
                // </ProtectedRoute>
              }/>
            </Route>
            <Route path="all-stores-summary">
              <Route
                index
                element={
                  // <ProtectedRoute admin={true}>
                    <AllStoresSummaryPage />
                  // </ProtectedRoute>
                }
              />
              <Route path=":adminStoreId" element={
              // <ProtectedRoute admin={true}>
                <StoreSummaryPage/>
                // </ProtectedRoute>
              }/>
            </Route>
            <Route path="my-store-summary">
              <Route
                index
                element={
                  // <ProtectedRoute admin={true}>
                    <ManagerStoreSummaryPage />
                  // </ProtectedRoute>
                }
              />
              <Route path=":managerStoreId" element={
              // <ProtectedRoute admin={true}>
                <OutdoorStorePage/>
                // </ProtectedRoute>
              }/>
            </Route>
          </Route>
          <Route path="*" element={<NotFoundPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default CustomRoutes;
