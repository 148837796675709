import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { getOrdersWithDatePHP } from "../../../api/orders-service";
import { Button, Col, Form, Row } from "react-bootstrap";
import { getCurrentDate } from "../../../helpers/functions/date-time";
import { toast } from "../../../helpers/swal";

const ordersColumns = [
  {
    name: "Index",
    selector: (row, index) => index + 1,
    sortable: "true",
  },
  {
    name: (
      <div className="text-center">
        <div className="text-center">
          Order
          <br />
          Total
        </div>
      </div>
    ),
    selector: (row) => row.orderTotal,
    sortable: "true",
    center: "true",
  },
  // {
  //   name: (
  //     <div className="text-center">
  //       <div className="text-center">Discount</div>
  //     </div>
  //   ),
  //   selector: (row) => row.discount_price,
  //   sortable: "true",
  //   center: "true",
  // },
  {
    name: (
      <div className="text-center">
        <div className="text-center">
          Items
          <br />
          Count
        </div>
      </div>
    ),
    selector: (row) => row.order_items.length,
    sortable: "true",
    center: "true",
  },
  {
    name: (
      <div className="text-center">
        <div className="text-center">
          Shipping
          <br />
          Amount
        </div>
      </div>
    ),
    selector: (row) => row.shippingAmount,
    sortable: "true",
    center: "true",
  },
  {
    name: (
      <div className="text-center">
        <div className="text-center">
          Tax
          <br />
          Amount
        </div>
      </div>
    ),
    selector: (row) => row.taxAmount,
    sortable: "true",
    center: "true",
  },
  {
    name: (
      <div className="text-center">
        <div className="text-center">
          (0.3% + 0.25$)
          <br />
          Processing
          <br />
          Fee
        </div>
      </div>
    ),
    selector: (row) => row.processing_fee,
    sortable: "true",
    center: "true",
  },
  {
    name: (
      <div className="text-center">
        <div className="text-center">
          (0.65%)
          <br />
          Transaction
          <br />
          Fee
        </div>
      </div>
    ),
    selector: (row) => row.transaction_fee,
    sortable: "true",
    center: "true",
  },
  {
    name: (
      <div className="text-center">
        <div className="text-center">
          (0.65%)
          <br />
          Transaction
          <br />
          Shipping
          <br />
          Fee
        </div>
      </div>
    ),
    selector: (row) => row.transaction_fee_shipping,
    sortable: "true",
    center: "true",
  },
  {
    name: (
      <div className="text-center">
        <div className="text-center">
          Shipment
          <br />
          Cost
        </div>
      </div>
    ),
    selector: (row) => row.shipmentCost,
    sortable: "true",
    center: "true",
  },
  {
    name: (
      <div className="text-center">
        <div className="text-center">
          (18%)
          <br />
          VAT
          <br />
          Processing
          <br />
          Fee
        </div>
      </div>
    ),
    selector: (row) => row.vat_processing_fee,
    sortable: "true",
    center: "true",
  },
  {
    name: (
      <div className="text-center">
        <div className="text-center">
          (0.20$)
          <br />
          Listing
          <br />
          Fee
        </div>
      </div>
    ),
    selector: (row) => row.listing_fee,
    sortable: "true",
    center: "true",
  },
  {
    name: (
      <div className="text-center">
        <div className="text-center">Profit</div>
      </div>
    ),
    selector: (row) => row.order_profit,
    sortable: "true",
    center: "true",
  },
];

const ordersTotalColumns = [
  {
    name: (
      <div className="text-center">
        <div className="text-center">
          Orders
          <br />
          Total
        </div>
      </div>
    ),
    selector: (row) => row.totalOrderTotal,
    center: "true",
  },
  // {
  //   name: (
  //     <div className="text-center">
  //       <div className="text-center">
  //         Total
  //         <br />
  //         Discounts
  //       </div>
  //     </div>
  //   ),
  //   selector: (row) => row.totalDiscountPrice,
  //   center: "true",
  // },
  {
    name: (
      <div className="text-center">
        <div className="text-center">
          Total
          <br />
          Items
          <br />
          Count
        </div>
      </div>
    ),
    selector: (row) => row.totalProductCount,
    center: "true",
  },
  {
    name: (
      <div className="text-center">
        <div className="text-center">
          Total
          <br />
          Shipping
          <br />
          Amount
        </div>
      </div>
    ),
    selector: (row) => row.totalShippingAmount,
    center: "true",
  },
  {
    name: (
      <div className="text-center">
        <div className="text-center">
          Total
          <br />
          Tax
          <br />
          Amount
        </div>
      </div>
    ),
    selector: (row) => row.totalTaxAmount,
    center: "true",
  },
  {
    name: (
      <div className="text-center">
        <div className="text-center">
          (0.3% + 0.25$)
          <br />
          Total
          <br />
          Processing
          <br />
          Fee
        </div>
      </div>
    ),
    selector: (row) => row.totalProcessingFee,
    center: "true",
  },
  {
    name: (
      <div className="text-center">
        <div className="text-center">
          (0.65%)
          <br />
          Total
          <br />
          Transaction
          <br />
          Fee
        </div>
      </div>
    ),
    selector: (row) => row.totalTransactionFee,
    center: "true",
  },
  {
    name: (
      <div className="text-center">
        <div className="text-center">
          (0.65%)
          <br />
          Total
          <br />
          Transaction
          <br />
          Shipping
          <br />
          Fee
        </div>
      </div>
    ),
    selector: (row) => row.totalTransactionFeeShipping,
    center: "true",
  },
  {
    name: (
      <div className="text-center">
        <div className="text-center">
          Total
          <br />
          Shipment
          <br />
          Cost
        </div>
      </div>
    ),
    selector: (row) => row.totalShipmentCost,
    center: "true",
  },
  {
    name: (
      <div className="text-center">
        <div className="text-center">
          (18%)
          <br />
          Total
          <br />
          VAT
          <br />
          Processing
          <br />
          Fee
        </div>
      </div>
    ),
    selector: (row) => row.totalVATProcessingFee,
    center: "true",
  },
  {
    name: (
      <div className="text-center">
        <div className="text-center">
          (0.20$)
          <br />
          Total
          <br />
          Listing
          <br />
          Fee
        </div>
      </div>
    ),
    selector: (row) => row.totalListingFee,
    center: "true",
  },
  {
    name: (
      <div className="text-center">
        <div className="text-center">
          Total
          <br />
          Profit
        </div>
      </div>
    ),
    selector: (row) => row.totalOrderProfit,
    center: "true",
  },
];

const OrderItemsTable = ({ orderItems }) => {
  const columns = [
    {
      name: "Name",
      selector: (row) => row.name,
      wrap: true,
    },
    {
      name: "Color",
      selector: (row) => row.color,
      wrap: true,
    },
    {
      name: "Size",
      selector: (row) => row.size,
      wrap: true,
    },
    {
      name: "Quantity",
      selector: (row) => row.quantity,
    },
    {
      name: "Shipment Cost",
      selector: (row) => row.kargo,
    },
    {
      name: "Product Cost",
      selector: (row) => row.urun,
    },
    {
      name: "Price",
      selector: (row) => row.unitPrice,
    },
  ];
  return <DataTable title="" columns={columns} data={orderItems} />;
};

const Orders = () => {
  const [loading, setLoading] = useState(true);
  const [ordersList, setOrdersList] = useState([]);
  const [ordersTotal, setOrdersTotal] = useState([]);
  const [filters, setFilters] = useState({
    beginning_date: "",
    ending_date: "",
  });
  const [errors, setErrors] = useState({
    beginning_date: "",
    ending_date: "",
  });

  const loadData = async () => {
    try {
      const formData = new FormData();
      formData.append("beginning_date", filters.beginning_date);
      formData.append("ending_date", filters.ending_date);
      const resp = await getOrdersWithDatePHP(formData);
      setOrdersList(resp.data.orders);
      setOrdersTotal(resp.data.total_orders);
    } catch (err) {
      const message = err.response ? err.response.data.message : err;
      toast(message, "error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line
  }, []);

  const ExpandedComponent = ({ data }) => {
    return (
      <div
        style={{
          padding: "20px",
          paddingTop: "1px",
          paddingBottom: "1px",
          backgroundColor: "rgb(248, 212, 66)",
        }}
      >
        <p style={{ padding: "0", paddingLeft: "10px", margin: "0" }}>
          Order Details:
        </p>
        <OrderItemsTable orderItems={data.order_items} />
      </div>
    );
  };

  const handleFilterChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const handleChange = () => {
    const newErrors = {};
    if (!filters.beginning_date) {
      newErrors.beginning_date = "Beginning date is required.";
    }
    if (!filters.ending_date) {
      newErrors.ending_date = "Ending date is required.";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
    if(filters.beginning_date && filters.ending_date){
      loadData();
    }
  };

  return (
    <div>
      <Row>
        <Col sm={5}>
          <Form.Group className="mb-3">
            <Form.Label htmlFor="beginning_date" style={{ fontWeight: "600" }}>
              Beginning Date
            </Form.Label>
            <Form.Control
              type="date"
              name="beginning_date"
              id="beginning_date"
              max={
                filters.ending_date && getCurrentDate() > filters.ending_date
                  ? filters.ending_date
                  : getCurrentDate()
              }
              value={filters.beginning_date}
              onChange={handleFilterChange}
              style={{
                borderRadius: "0.3rem",
                boxShadow: "0px 0px 20px 0px rgba(255, 255, 255, 0.8)",
              }}
              required
              isInvalid={errors.beginning_date !== ""}
            />
            <Form.Control.Feedback type="invalid">
              {errors.beginning_date}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col sm={5}>
          <Form.Group className="mb-3">
            <Form.Label htmlFor="ending_date" style={{ fontWeight: "600" }}>
              Ending Date
            </Form.Label>
            <Form.Control
              type="date"
              min={filters.beginning_date}
              name="ending_date"
              id="ending_date"
              value={filters.ending_date}
              onChange={handleFilterChange}
              max={getCurrentDate()}
              style={{
                borderRadius: "0.3rem",
                boxShadow: "0px 0px 20px 0px rgba(255, 255, 255, 0.8)",
              }}
              required
              isInvalid={errors.ending_date !== ""}
            />
            <Form.Control.Feedback type="invalid">
              {errors.ending_date}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col
          sm={2}
          className="text-end d-flex align-items-center justify-content-end"
        >
          <Button
            type="button"
            style={{
              width: "100%",
              marginTop: "1rem",
              color: "white",
              fontWeight: "600",
              borderRadius: "0.3rem",
              boxShadow: "0px 0px 20px 0px rgba(255, 255, 255, 0.8)",
            }}
            onClick={handleChange}
            disabled={loading}
          >
            Search
          </Button>
        </Col>
      </Row>
      <DataTable
        title="Total"
        columns={ordersTotalColumns}
        data={ordersTotal}
        progressPending={loading}
        highlightOnHover
      />
      <DataTable
        title="Orders List"
        columns={ordersColumns}
        data={ordersList}
        progressPending={loading}
        expandableRows
        expandableRowsComponent={(row) => <ExpandedComponent data={row.data} />}
        highlightOnHover
        customStyles={{
          headCells: {
            style: {
              paddingLeft: "0px",
              paddingRight: "0px",
            },
          },
          cells: {
            style: {
              paddingLeft: "0",
              paddingRight: "0",
            },
          },
        }}
      />
    </div>
  );
};

export default Orders;
