import React, { useEffect, useState } from "react";
import { Button, Form, InputGroup, Spinner } from "react-bootstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useAppSelector } from "../../../store/hooks";
import { toast } from "../../../helpers/swal";
import { updateUser } from "../../../api/user-service";
import { HiOutlineMail } from "react-icons/hi";
import { BsCreditCard, BsTelephone } from "react-icons/bs";

const ProfileForm = () => {
  const [loading, setLoading] = useState(false);
  const user = useAppSelector((state) => state.auth.user);
  const { name, tel_no, username } = user;

  const initialValues = {
    name,
    tel_no,
    username,
  };

  const validationSchema = Yup.object({
    name: Yup.string()
      .required("Please enter your full name")
      .min(3, "Please enter 3 characters"),
    tel_no: Yup.string()
      .required("Please enter your phone number")
      .test(
        "is_includes_",
        "Please enter a valid phone number",
        (value) => value && !value.includes("_")
      ),
  });

  const onSubmit = async (values) => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("name", values.name);
      formData.append("tel_no", values.tel_no);
      await updateUser(formData);
      toast("Profile was updated", "success");
    } catch (err) {
      toast(err.response.data.message, "error");
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  useEffect(() => {
    formik.setValues({
      name: user.name,
      tel_no: user.tel_no,
      username: user.username,
    });
  }, [user]);

  return (
    <Form noValidate onSubmit={formik.handleSubmit} className="p-4">
      <h3>Update Profile</h3>
      {/* <fieldset disabled={builtIn}> */}
      <InputGroup className="mb-3">
        <InputGroup.Text>
          <BsCreditCard />
        </InputGroup.Text>
        <Form.Control
          className="email-input"
          placeholder="Full Name"
          type="text"
          {...formik.getFieldProps("name")}
          isInvalid={formik.touched.name && formik.errors.name}
          isValid={formik.touched.name && !formik.errors.name}
        />
        <Form.Control.Feedback type="invalid">
          {formik.errors.name}
        </Form.Control.Feedback>
      </InputGroup>
      <InputGroup className="mb-3">
        <InputGroup.Text>
          <BsTelephone />
        </InputGroup.Text>
        <Form.Control
          type="text"
          placeholder="Phone Number"
          // as={ReactInputMask}
          // mask="(999) 999-9999"
          className="email-input"
          {...formik.getFieldProps("tel_no")}
          isInvalid={formik.touched.tel_no && formik.errors.tel_no}
          isValid={formik.touched.tel_no && !formik.errors.tel_no}
        />
        <Form.Control.Feedback type="invalid">
          {formik.errors.tel_no}
        </Form.Control.Feedback>
      </InputGroup>
      <InputGroup className="mb-3">
        <InputGroup.Text>
          <HiOutlineMail />
        </InputGroup.Text>
        <Form.Control
          type="email"
          placeholder="Email"
          className="email-input"
          {...formik.getFieldProps("username")}
          isInvalid={formik.touched.username && formik.errors.username}
          isValid={formik.touched.username && !formik.errors.username}
          disabled
        />
        <Form.Control.Feedback type="invalid">
          {formik.errors.username}
        </Form.Control.Feedback>
      </InputGroup>

      <Button
        variant="primary"
        type="submit"
        disabled={!(formik.dirty && formik.isValid) || loading}
      >
        {loading && <Spinner animation="border" size="sm" />} Update
      </Button>
      {/* </fieldset> */}
    </Form>
  );
};

export default ProfileForm;
