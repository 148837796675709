import React from 'react'
import AnanymousTemplate from '../../templates/ananymous-template'
import Register from '../../components/common/auth/register'

const RegisterPage = () => {
  return (
    <AnanymousTemplate>
        <Register/>
    </AnanymousTemplate>
  )
}

export default RegisterPage