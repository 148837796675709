import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { getGetStoresInfoPHP, getUpdateStore } from "../../../api/stores-service";
import { useNavigate } from "react-router-dom";
import { Alert } from "react-bootstrap";
import { toast } from "../../../helpers/swal";

const columns = [
  {
    name: "Index",
    selector: (row, index) => index + 1,
  },
  {
    name: "Store Id",
    selector: (row) => row.storeId,
  },
  {
    name: "Store Name",
    selector: (row) => row.storeName,
    wrap: true,
  },
  {
    name: "Marketplace",
    selector: (row) => row.marketplaceName,
  },
  {
    name: "Create Date",
    selector: (row) => row.CreateDate,
  },
];

const IndoorStores = () => {
  const [loading, setLoading] = useState(true);
  const [indoorStoresList, setIndoorStoresList] = useState([]);
  const [updateStores, setupdateStores] = useState([]);
  const navigate = useNavigate();

  const loadData = async () => {
    try {
      const resp = await getGetStoresInfoPHP();
      const respUpdateStore = await getUpdateStore();
      const filteredStores = resp.data.stores.filter((store) => store.storeLocation == 1);
      setIndoorStoresList(filteredStores);
      setupdateStores(respUpdateStore.data);
    } catch (err) {
      const message = err.response ? err.response.data.message : err;
      toast(message, "error");
    } finally {
      setLoading(false);
    }
  };

  const handleRowClicked = (row) => {
    navigate(`/dashboard/indoor-stores/${row.storeId}`);
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <div>
      {updateStores.new_store_count >= 1 && (updateStores.new_stores !== "DIŞ" || updateStores.new_stores !== "DIS" || updateStores.new_stores !== "DIŞŞ" || updateStores.new_stores !== "zzz") && (
        <>
          <Alert variant="success">
            {`${updateStores.new_store_count} new stores have been added to our system. Here are the store name(s):`}
            <ul>
              {updateStores.new_stores &&
                updateStores.new_stores.map((store, index) => (
                  <li key={index}>{store}</li>
                ))}
            </ul>
          </Alert>
        </>
      )}
      <DataTable
        title="Indoor Stores List"
        columns={columns}
        data={indoorStoresList}
        progressPending={loading}
        // pagination
        // paginationServer
        // paginationTotalRows={totalRows}
        // onChangeRowsPerPage={handleChangeRowsPerPage}
        // onChangePage={handleChangePage}
        onRowClicked={handleRowClicked}
        highlightOnHover // Hover üzerine gelindiğinde satırları vurgula
        customStyles={{
          rows: {
            style: {
              // Her satır için tıklanabilir sınıfı ekleyin
              cursor: "pointer",
            },
          },
        }}
      />
    </div>
  );
};

export default IndoorStores;