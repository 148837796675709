import React from "react";
import AdminTemplate from "../../templates/admin-template";
import AllStoresSummary from "../../components/admin/all-stores-summary/all-stores-summary";

const AllStoresSummaryPage = () => {
  return (
    <AdminTemplate>
      <AllStoresSummary />
    </AdminTemplate>
  );
};

export default AllStoresSummaryPage;
