import React, { useEffect, useState } from "react";
import "./dashboard-cards.scss";
import { Card, Col, Container, Row } from "react-bootstrap";
import { HiOutlineAcademicCap } from "react-icons/hi";
import { BsShop, BsShopWindow } from "react-icons/bs";
import { RiMoneyDollarBoxLine } from "react-icons/ri";
import { FiUser } from "react-icons/fi";
import { MdOutlineShoppingCart } from "react-icons/md";
import { Link } from "react-router-dom";
import { getGetStoreCountPHP } from "../../../api/stores-service";
import { toast } from "../../../helpers/swal";
import { useAppSelector } from "../../../store/hooks";

const DashboardCards = () => {
  const [storeQuantity, setStoreQuantity] = useState([]);
  const { isUserLogin, user } = useAppSelector((state) => state.auth);

  const loadData = async () => {
    try {
      const resp = await getGetStoreCountPHP();
      setStoreQuantity(resp.data);
    } catch (err) {
      const message = err.response ? err.response.data.message : err;
      toast(message, "error");
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    user.profile_type == 3 && (
      <>
        <Container fluid className="dashboard-cards">
          <Row className="g-4">
            <Col sm={6} xl={3}>
              <Link to="/dashboard/orders">
                <Card className="cards">
                  <Card.Text>
                    <MdOutlineShoppingCart />
                  </Card.Text>
                  <Card.Title>Orders</Card.Title>
                  <span>
                    <span className="fs-6" style={{ marginRight: "2rem" }}>
                      (Today)
                    </span>{" "}
                    {storeQuantity.order_count}
                  </span>
                </Card>
              </Link>
            </Col>
            {/* <Col sm={6} xl={3}>
          <Link to="/dashboard/stores">
            <Card className="cards">
              <Card.Text>
                <BsShop />
              </Card.Text>
              <Card.Title>Stores</Card.Title>
              <span>43</span>
            </Card>
          </Link>
        </Col> */}
            <Col sm={6} xl={3}>
              <Link to="/dashboard/all-stores-summary">
                <Card className="cards">
                  <Card.Text>
                    <BsShop />
                  </Card.Text>
                  <Card.Title>Summary</Card.Title>
                  <span>{storeQuantity.outer_store_count}</span>
                </Card>
              </Link>
            </Col>
            <Col sm={6} xl={3}>
              <Link to="/dashboard/indoor-stores">
                <Card className="cards">
                  <Card.Text>
                    <BsShop />
                  </Card.Text>
                  <Card.Title>Indoor Stores</Card.Title>
                  <span>{storeQuantity.inner_store_count}</span>
                </Card>
              </Link>
            </Col>
            <Col sm={6} xl={3}>
              <Link to="/dashboard/outdoor-stores">
                <Card className="cards">
                  <Card.Text>
                    <BsShopWindow />
                  </Card.Text>
                  <Card.Title>Outdoor Stores</Card.Title>
                  <span>{storeQuantity.outer_store_count}</span>
                </Card>
              </Link>
            </Col>
            {/* <Col sm={6} xl={3}>
          <Link to="/dashboard">
            <Card className="cards">
              <Card.Text>
                <RiMoneyDollarBoxLine />
              </Card.Text>
              <Card.Title>Payments</Card.Title>
              <span>556,000₺</span>
            </Card>
          </Link>
        </Col>
        <Col sm={6} xl={3}>
          <Link to="/dashboard">
            <Card className="cards">
              <Card.Text>
                <FiUser />
              </Card.Text>
              <Card.Title>Users</Card.Title>
              <span>3</span>
            </Card>
          </Link>
        </Col> */}
          </Row>
        </Container>
      </>
    )
  );
};

export default DashboardCards;

// import React, { useState, useEffect } from "react";
// import {
//   Button,
//   Card,
//   Col,
//   Container,
//   Form,
//   Row,
//   Spinner,
// } from "react-bootstrap";
// import {
//   FaUsers,
//   FaTags,
//   FaBoxes,
//   FaClipboardList,
//   FaComments,
//   FaTrademark,
//   FaLayerGroup,
//   FaEnvelopeOpenText,
// } from "react-icons/fa";
// import { Link } from "react-router-dom";
// import { useAppSelector } from "../../../store/hooks";
// import "./dashboard-card.scss";
// // import {
// //   deleteDashboardDatabase,
// //   getDashboardDatabase,
// // } from "../../../api/admin-database-service";
// // import { question, toast } from "../../../helpers/functions/swal";

// const DashboardCard = () => {
//   const user = useAppSelector((state) => state.auth.user);
//   const [loading, setLoading] = useState(false);
//   const [deleting, setDeleting] = useState(false);
//   const [database, setDatabase] = useState({});

//   const loadData = async () => {
//     setLoading(true);
//     try {
//       // const resp = await getDashboardDatabase();
//       // const {
//       //   brandCount,
//       //   categoryCount,
//       //   contactMessageCount,
//       //   couponCount,
//       //   customerCount,
//       //   orderCount,
//       //   productCount,
//       //   reviewCount,
//       // } = resp.data.data;
//       // setDatabase({
//       //   brandCount,
//       //   categoryCount,
//       //   contactMessageCount,
//       //   couponCount,
//       //   customerCount,
//       //   orderCount,
//       //   productCount,
//       //   reviewCount,
//       // });
//     } catch (err) {
//       // toast(err.response.data.message, "error");
//     } finally {
//       setLoading(false);
//     }
//   };

//   useEffect(() => {
//     // loadData();
//     // eslint-disable-next-line
//   }, []);

//   return (
//     <Container className="dashboards-card mt-5">
//       <Row className="g-4">
//         {/* <Col sm={6} md={4}>
//           <Link to="">
//             <Card>
//               <Card.Title>
//                 <Form.Check label="Customers" />
//               </Card.Title>
//               <span>{}</span>
//               <Card.Text>
//                 <FaUsers />
//               </Card.Text>
//             </Card>
//           </Link>
//         </Col>

//         <Col sm={6} md={4}>
//           <Link to="">
//             <Card>
//               <Card.Title>
//                 <Form.Check label="Brands" />
//               </Card.Title>
//               <span>{}</span>
//               <Card.Text>
//                 <FaTrademark />
//               </Card.Text>
//             </Card>
//           </Link>
//         </Col>

//         <Col sm={6} md={4}>
//           <Link to="">
//             <Card>
//               <Card.Title>
//                 <Form.Check label="Categories" />
//               </Card.Title>
//               <span>{}</span>
//               <Card.Text>
//                 <FaLayerGroup />
//               </Card.Text>
//             </Card>
//           </Link>
//         </Col>

//         <Col sm={6} md={4}>
//           <Link to="">
//             <Card>
//               <Card.Title>
//                 <Form.Check label="Products" />
//               </Card.Title>
//               <span>{}</span>
//               <Card.Text>
//                 <FaBoxes />
//               </Card.Text>
//             </Card>
//           </Link>
//         </Col> */}

//         <Col sm={6} md={4}>
//           <Link to="/orders">
//             <Card>
//               <Card.Title>
//                 <Form.Check label="Orders" />
//               </Card.Title>
//               <span>{}</span>
//               <Card.Text>
//                 <FaClipboardList />
//               </Card.Text>
//             </Card>
//           </Link>
//         </Col>

//         {/* <Col sm={6} md={4}>
//           <Link to="">
//             <Card>
//               <Card.Title>
//                 <Form.Check label="Coupons" />
//               </Card.Title>
//               <span>{}</span>
//               <Card.Text>
//                 <FaTags />
//               </Card.Text>
//             </Card>
//           </Link>
//         </Col>

//         <Col sm={6} md={4}>
//           <Link to="">
//             <Card>
//               <Card.Title>
//                 <Form.Check label="Reviews" />
//               </Card.Title>
//               <span>{}</span>
//               <Card.Text>
//                 <FaComments />
//               </Card.Text>
//             </Card>
//           </Link>
//         </Col>

//         <Col sm={6} md={4}>
//           <Link to="">
//             <Card>
//               <Card.Title>
//                 <Form.Check label="Messages" />
//               </Card.Title>
//               <span>{}</span>
//               <Card.Text>
//                 <FaEnvelopeOpenText />
//               </Card.Text>
//             </Card>
//           </Link>
//         </Col> */}
//       </Row>
//     </Container>
//   );
// };

// export default DashboardCard;
