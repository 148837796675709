import React from "react";
import "./dashboard-header.scss";
import { SlActionUndo } from "react-icons/sl";
import { GoBell } from "react-icons/go";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const DashboardHeader = () => {
  const navigate = useNavigate();
  return (
    <Container fluid className="header-icons mt-4">
      <span onClick={() =>  navigate(-1)}>
        <SlActionUndo />
      </span>
      <span>
        <GoBell />
      </span>
    </Container>
  );
};

export default DashboardHeader;
