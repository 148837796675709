import React from 'react'
import Summary from '../../components/manager/manager-store-summary/summary'
import ExternalManagerTemplate from '../../templates/external-manager-template'

const ManagerStoreSummaryPage = () => {
  return (
    <ExternalManagerTemplate>
        <Summary />
    </ExternalManagerTemplate>
  )
}

export default ManagerStoreSummaryPage