import React, { useEffect, useState } from "react";
import { Alert, Button, Spinner } from "react-bootstrap";
import DataTable from "react-data-table-component";
import {
  getGetStoresInfoPHP,
  getUpdateStore,
} from "../../../api/stores-service";
import "./stores.scss";
import { toast } from "../../../helpers/swal";

const columns = [
  {
    name: "Index",
    selector: (row, index) => index + 1,
  },
  {
    name: "Store Name",
    selector: (row) => row.storeName,
  },
  {
    name: "Marketplace",
    selector: (row) => row.marketplaceName,
  },
  {
    name: "Create Date",
    selector: (row) => row.CreateDate,
  },
  {
    name: "Active/Passive",
    selector: (row) => (row.isActive == 1 ? "Active" : "Passive"),
  },
];

const Stores = () => {
  const [loading, setLoading] = useState(true);
  const [updateStores, setupdateStores] = useState([]);
  const [storesList, setStoresList] = useState([]);

  const loadData = async () => {
    try {
      const resp = await getGetStoresInfoPHP();
      const respUpdateStore = await getUpdateStore();
      setStoresList(resp.data.stores);
      setupdateStores(respUpdateStore.data);
    } catch (err) {
      const message = err.response ? err.response.data.message : err;
      toast(message, "error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <div>
      {updateStores.new_store_count >= 1 && (
        <>
          <Alert variant="success">
            {`${updateStores.new_store_count} new stores have been added to our system. Here are the store name(s):`}
            <ul>
              {updateStores.new_stores &&
                updateStores.new_stores.map((store, index) => (
                  <li key={index}>{store}</li>
                ))}
            </ul>
          </Alert>
        </>
      )}
      <DataTable
        title="Stores List"
        columns={columns}
        data={storesList}
        progressPending={loading}
        // pagination
        // paginationServer
        // paginationTotalRows={totalRows}
        // onChangeRowsPerPage={handleChangeRowsPerPage}
        // onChangePage={handleChangePage}
        // onRowClicked={handleRowClicked}
      />
    </div>
  );
};

export default Stores;
