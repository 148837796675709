import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Button, Col, Form, Row } from "react-bootstrap";
import { getCurrentDate } from "../../../helpers/functions/date-time";
import { getManagerStoreOrdersWithDate } from "../../../api/manager-store-service";
import { toast } from "../../../helpers/swal";
import { getAllStoresSummary } from "../../../api/stores-service";
import { useNavigate } from "react-router-dom";

const ordersColumns = [
  {
    name: "Index",
    selector: (row, index) => index + 1,
    sortable: "true",
  },
  {
    name: (
      <div className="text-center">
        <div className="text-center">
          Store
          <br />
          Name
        </div>
      </div>
    ),
    selector: (row) => row.storeName,
    sortable: "true",
    center: "true",
  },
  {
    name: (
      <div className="text-center">
        <div className="text-center">
          Total
          <br />
          Orders
        </div>
      </div>
    ),
    selector: (row) => row.total_orders,
    sortable: "true",
    center: "true",
  },
  {
    name: (
      <div className="text-center">
        <div className="text-center">
          Total
          <br />
          Products
        </div>
      </div>
    ),
    selector: (row) => row.total_urun,
    sortable: "true",
    center: "true",
  },
  {
    name: (
      <div className="text-center">
        <div className="text-center">
          Total
          <br />
          Shipping
          <br />
          Cost
        </div>
      </div>
    ),
    selector: (row) => row.total_kargo,
    sortable: "true",
    center: "true",
  },
  {
    name: (
      <div className="text-center">
        <div className="text-center">
          Total
          <br />
          Cost
        </div>
      </div>
    ),
    selector: (row) => row.total_order_maliyet,
    sortable: "true",
    center: "true",
  },
];

const ordersTotalColumns = [
  {
    name: (
      <div className="text-center">
        <div className="text-center">
          Total
          <br />
          Orders
        </div>
      </div>
    ),
    selector: (row) => row.total_orders,
    center: "true",
  },
  {
    name: (
      <div className="text-center">
        <div className="text-center">
          Total
          <br />
          Product
          <br />
          Cost
        </div>
      </div>
    ),
    selector: (row) => row.total_urun,
    center: "true",
  },
  {
    name: (
      <div className="text-center">
        <div className="text-center">
          Total
          <br />
          Shipping
          <br />
          Cost
        </div>
      </div>
    ),
    selector: (row) => row.total_kargo,
    center: "true",
  },
  {
    name: (
      <div className="text-center">
        <div className="text-center">
          Total
          <br />
          Order
          <br />
          Cost
        </div>
      </div>
    ),
    selector: (row) => row.total_order_maliyet,
    center: "true",
  }
];

const AllStoresSummary = () => {
  const [loading, setLoading] = useState(true);
  const [ordersList, setOrdersList] = useState([]);
  const [ordersTotal, setOrdersTotal] = useState([]);
  const [filters, setFilters] = useState({
    date: "",
  });
  const [errors, setErrors] = useState({
    date: "",
  });

  const navigate = useNavigate();

  const loadData = async () => {
    try {
      const formData = new FormData();
      formData.append("date", filters.date);
      const resp = await getAllStoresSummary(formData);
      setOrdersList(resp.data.data);
      console.log(resp.data)
      setOrdersTotal(resp.data.total_values);
    } catch (err) {
      const message = err.response ? err.response.data.message : err;
      toast(message, "error");
    } finally {
      setLoading(false);
    }
  };

  const handleRowClicked = (row) => {
    navigate(`/dashboard/all-stores-summary/${row.storeId}`);
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line
  }, []);

  const handleFilterChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const handleChange = () => {
    const newErrors = {};
    if (!filters.date) {
      newErrors.date = "Beginning date is required.";
    }
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
    if(filters.date){
      loadData();
    }
  };

  return (
    <div>
      <Row style={{ display: "flex", justifyContent: "center" }}>
        <Col sm={3}>
          <Form.Group className="mb-3">
            <Form.Label htmlFor="date" style={{ fontWeight: "600" }}>
              Month
            </Form.Label>
            <Form.Control
              type="month"
              name="date"
              id="date"
              max={getCurrentDate()}
              value={filters.date}
              onChange={handleFilterChange}
              style={{
                borderRadius: "0.3rem",
                boxShadow: "0px 0px 20px 0px rgba(255, 255, 255, 0.8)",
                maxWidth: "200px",
              }}
              required
              isInvalid={errors.date !== ""}
            />
            <Form.Control.Feedback type="invalid">
              {errors.date}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col
          sm={2}
          className="text-end d-flex align-items-center justify-content-end"
        >
          <Button
            type="button"
            style={{
              width: "100%",
              marginTop: "1rem",
              color: "white",
              fontWeight: "600",
              borderRadius: "0.3rem",
              boxShadow: "0px 0px 20px 0px rgba(255, 255, 255, 0.8)",
            }}
            onClick={handleChange}
            disabled={loading}
          >
            Search
          </Button>
        </Col>
      </Row>
      <DataTable
        title="Total"
        columns={ordersTotalColumns}
        data={ordersTotal}
        progressPending={loading}
        highlightOnHover
      />
      <DataTable
        title="Orders List"
        columns={ordersColumns}
        data={ordersList}
        progressPending={loading}
        onRowClicked={handleRowClicked}
        highlightOnHover
        customStyles={{
          headCells: {
            style: {
              paddingLeft: "0px",
              paddingRight: "0px",
            },
          },
          rows: {
            style: {
              cursor: "pointer",
            },
          },
          cells: {
            style: {
              paddingLeft: "0",
              paddingRight: "0",
            },
          },
        }}
      />
    </div>
  );
};

export default AllStoresSummary;