import axios from "axios";
import { settings } from "../helpers/settings";
import authHeader from "../helpers/functions/auth-header";

const API_URL = settings.apiURL;

export const getGetStoresInfoPHP = () => {
  return axios.get(`${API_URL}/get_stores_info.php`);
};

export const getGetStoreCountPHP = () => {
  return axios.get(`${API_URL}/ic_dis_magaza_sayisi.php`);
};

export const getUpdateStore = () => {
  return axios.get(`${API_URL}/update_stores.php`);
};

export const getGetStoreOrdersPHP = (formData) => {
  return axios.post(`${API_URL}/get_store_orders.php`, formData);
};

export const getAllStoresSummary = (formData) => {
  return axios.post(`${API_URL}/all_store_summary.php`, formData, { headers: authHeader() });
};

// endpoint değişecek
export const getStoreSummary = (formData) => {
  return axios.post(`${API_URL}/all_store_summary.php`, formData, { headers: authHeader() });
};
