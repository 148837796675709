import React from "react";
import { Container, Row, Col, Alert } from "react-bootstrap";
import { FaUserCircle } from "react-icons/fa";
import { useAppSelector } from "../../../store/hooks";
import PasswordForm from "./password-form";
import ProfileForm from "./profile-form";
import "./profile.scss"

const Profile = () => {
  const user = useAppSelector((state) => state.auth.user);
  const { name, tel_no, username, profile_type, store_id} = user;

  return (
    <Container className="profile">
      <Row className="g-5">
        <Col lg={12} className="text-center">
          <FaUserCircle size="120" />
          <h4>
            {name}
          </h4>
          <em>{username}</em>

          {/* {builtIn && (
            <Alert variant="warning mt-5">
              Built-in accounts cannot be updated
            </Alert>
          )} */}
        </Col>
        <Col lg={6}>
          <ProfileForm />
        </Col>
        <Col lg={6}>
          <PasswordForm />
        </Col>
      </Row>
    </Container>
  );
};

export default Profile;
