import authHeader from "../helpers/functions/auth-header";
import axios from "axios";
import { settings } from "../helpers/settings";

const API_URL = settings.apiURL;

// Register
export const register = (formData) => {
  return axios.post(`${API_URL}/register.php`, formData);
};

// Login
export const login = (formData) => {
  return axios.post(`${API_URL}/login_auth.php`, formData);
};

// get getUser
export const getUser = () => {
  return axios.get(`${API_URL}/get_user.php`, { headers: authHeader() });
};

// put UpdateUser
export const updateUser = (formData) => {
  return axios.post(`${API_URL}/update_user.php`, formData, { headers: authHeader() });
};

// patch updatePassword
export const updatePassword = (formData) => {
  return axios.post(`${API_URL}/update_password.php`, formData, {
    headers: authHeader()
  });
};
