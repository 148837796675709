import React from "react";
import AdminTemplate from "../../templates/admin-template";
import StoreSummary from "../../components/admin/all-stores-summary/store-summary";

const StoreSummaryPage = () => {
  return (
    <AdminTemplate>
      <StoreSummary />
    </AdminTemplate>
  );
};

export default StoreSummaryPage;
