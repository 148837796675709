import React from 'react'
import AnanymousTemplate from '../../templates/ananymous-template'
import Login from '../../components/common/auth/login'

const LoginPage = () => {
  return (
    <AnanymousTemplate>
        <Login/>
    </AnanymousTemplate>
  )
}

export default LoginPage