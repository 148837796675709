import React from "react";
import { Button, Container } from "react-bootstrap";
import logo from "../../assets/img/logo/txmaya-200x200.png";
import { settings } from "../../helpers/settings";
import { Link } from "react-router-dom";
import "./header.scss"
import { useLocation } from "react-router-dom";

const Header = () => {
  const location = useLocation();
  return (
    <Container fluid className="fixed-top header-top" bcgColor="primary">
      <div className="header">
        <div className="mx-5 mt-3 mb-2 d-flex align-items-center justify-content-between">
          <Link to="/">
            <img
              src={logo}
              alt={settings.siteName}
              className="img-fluid"
              width={50}
            />
          </Link>
          <div className="d-none links">
          <Button
              variant="primary"
              style={{ borderRadius: "0.3rem", boxShadow: "0px 0px 20px 0px rgba(255, 255, 255, 0.8)", color: "white", fontWeight: "500", width: "10rem" }}
              as={Link}
              to="https://customzon.com/"
            >
              Customzon
            </Button>
            <Button
              variant="primary"
              style={{ borderRadius: "0.3rem", boxShadow: "0px 0px 20px 0px rgba(255, 255, 255, 0.8)", color: "white", fontWeight: "500", width: "10rem" }}
              as={Link}
              to="https://txmaya.com/"
            >
              TX Maya
            </Button>
            <Button
              variant="primary"
              style={{ borderRadius: "0.3rem", boxShadow: "0px 0px 20px 0px rgba(255, 255, 255, 0.8)", color: "white", fontWeight: "500", width: "10rem" }}
              as={Link}
              to="https://dtftransferscustom.com/"
            >
              DTF Transfers
            </Button>
            <Button
              variant="primary"
              style={{ borderRadius: "0.3rem", boxShadow: "0px 0px 20px 0px rgba(255, 255, 255, 0.8)", color: "white", fontWeight: "500", width: "10rem" }}
              as={Link}
              to="https://thedtfdepot.com/"
            >
              The DTF Depot
            </Button>
            <Button
              variant="primary"
              style={{ borderRadius: "0.3rem", boxShadow: "0px 0px 20px 0px rgba(255, 255, 255, 0.8)", color: "white", fontWeight: "500", width: "10rem"}}
              as={Link}
              to="https://thedtfmart.com/"
            >
              The DTF Mart
            </Button>
          </div>
          <div className="d-flex align-items-center gap-3">
            {/* {location.pathname !== "/sku-generator" ? (
              <Button
              variant="primary"
              style={{ borderRadius: "0.3rem", boxShadow: "0px 0px 20px 0px rgba(255, 255, 255, 0.8)", color: "white", fontWeight: "500",width: "10rem" }}
              as={Link}
              to="/sku-generator"
            >
              SKU GENERATE
            </Button>
            ) : (<Button
              variant="primary"
              style={{ borderRadius: "0.3rem", boxShadow: "0px 0px 20px 0px rgba(255, 255, 255, 0.8)", color: "white", fontWeight: "500", width: "10rem" }}
              as={Link}
              to="/"
            >
              SIGN IN
            </Button>)} */}
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Header;
