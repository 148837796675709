import React, { useState } from "react";
import { Form, Button, InputGroup, Container, Spinner } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { HiOutlineMail } from "react-icons/hi";
import { BsTelephone, BsCreditCard } from "react-icons/bs";
import * as Yup from "yup";
import PasswordInput from "./password-input";
import { toast } from "../../../helpers/swal";
import { register } from "../../../api/user-service";
import "./register.scss";

const Register = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  function handleClick() {
    navigate("/");
  }

  const initialValues = {
    username: "",
    password: "",
    confirmPassword: "",
    name: "",
    tel_no: "",
  };

  const validationSchema = Yup.object({
    username: Yup.string()
      .email("Please enter a valid email")
      .required("Please enter your email"),
    password: Yup.string()
      .required("Please enter a password")
      .min(8, "Please provide at least 8 characters")
      .matches(/[a-z]+/, "One lowercase character")
      .matches(/[A-Z]+/, "One uppercase character")
      .matches(/[@$!%*#?&.]+/, "One special character")
      .matches(/\d+/, "One number"),
    confirmPassword: Yup.string()
      .required("Please re-enter your password")
      .oneOf([Yup.ref("password")], "Password fields dosen't match"),
    name: Yup.string()
      .required("Please enter your full name")
      .min(3, "Please enter 3 characters"),
    tel_no: Yup.string()
      .required("Please enter your phone number")
      .test(
        "is_includes_",
        "Please enter a valid phone number",
        (val) => val && !val.includes("_")
      ),
  });

  const onSubmit = async (values) => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("username", values.username);
      formData.append("password", values.password);
      formData.append("name", values.name);
      formData.append("tel_no", values.tel_no);
      await register(formData);
      toast("You're registered", "success");
      navigate("/");
      formik.resetForm();
    } catch (err) {
      const message = err.response ? err.response.data.message : err;
      toast(message, "error");
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return (
    <Container fluid className="registerDiv">
      <Form noValidate onSubmit={formik.handleSubmit} className="p-4">
        <h2>MAYA TX SHOP</h2>
        <h3>SIGN UP</h3>
        <p>Fill in the boxes below to create your account</p>

        <InputGroup className="mt-2 mb-3">
          <InputGroup.Text>
            <BsCreditCard />
          </InputGroup.Text>
          <Form.Control
          className="email-input"
            placeholder="Full Name"
            type="text"
            {...formik.getFieldProps("name")}
            isValid={formik.touched.name && !formik.errors.name}
            isInvalid={formik.touched.name && !!formik.errors.name}
          />
          <Form.Control.Feedback type="invalid">
            {formik.errors.name}
          </Form.Control.Feedback>
        </InputGroup>

        <InputGroup className="mb-3">
          <InputGroup.Text>
            <BsTelephone />
          </InputGroup.Text>
          <Form.Control
          className="email-input"
            type="text"
            placeholder="Phone Number"
            {...formik.getFieldProps("tel_no")}
            isInvalid={formik.touched.tel_no && formik.errors.tel_no}
            isValid={formik.touched.tel_no && !formik.errors.tel_no}
          />
          <Form.Control.Feedback type="invalid">
            {formik.errors.tel_no}
          </Form.Control.Feedback>
        </InputGroup>

        <InputGroup className="mb-3">
          <InputGroup.Text>
            <HiOutlineMail />
          </InputGroup.Text>
          <Form.Control
            className="email-input"
            type="email"
            placeholder="Email"
            {...formik.getFieldProps("username")}
            isInvalid={formik.touched.username && formik.errors.username}
            isValid={formik.touched.username && !formik.errors.username}
          />
          <Form.Control.Feedback type="invalid">
            {formik.errors.username}
          </Form.Control.Feedback>
        </InputGroup>

        <PasswordInput
          placeholder="Password"
          {...formik.getFieldProps("password")}
          isInvalid={formik.touched.password && formik.errors.password}
          isValid={formik.touched.password && !formik.errors.password}
          error={formik.errors.password}
        />
        
        <PasswordInput
          placeholder="Confirm Password"
          {...formik.getFieldProps("confirmPassword")}
          isInvalid={formik.touched.confirmPassword && formik.errors.confirmPassword}
          isValid={formik.touched.confirmPassword && !formik.errors.confirmPassword}
          error={formik.errors.confirmPassword}
        />
        {/* <Form.Group
          className="mb-3 rememberForgot"
          controlId="formBasicCheckbox"
        >
          <Form.Check
            type="checkbox"
            label="Remember me"
            name="rememberMe"
            checked={rememberMe}
            onChange={(e) => setRememberMe(e.target.checked)}
          />
          <Link to="/forgot-password" style={{ textDecoration: "none" }}>
            <Form.Text className="forgotText">Forgot password?</Form.Text>
          </Link>
        </Form.Group> */}

        <Button
          variant="primary"
          type="submit"
          className="mb-2"
          disabled={!(formik.dirty && formik.isValid) || loading}
        >
          {loading && <Spinner animation="border" size="sm" />} SIGN UP
        </Button>

        <hr className="mt-1" />

        <Form.Group className="mb-2">
          <Form.Text>Do you have already an account?</Form.Text>
          <Form.Text onClick={handleClick}>
            <span style={{ cursor: "pointer", fontWeight: "bold"}}> &nbsp;Login Now!</span>
          </Form.Text>
        </Form.Group>
      </Form>
    </Container>
  );
};

export default Register;
