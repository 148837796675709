import React from 'react'
import NotFound from '../../components/common/not-found/not-found'

const NotFoundPage = () => {
  return (
    <>
        <NotFound/>
    </>
  )
}

export default NotFoundPage