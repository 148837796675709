import React from 'react'
import Orders from '../../components/admin/orders/orders'
import AdminTemplate from '../../templates/admin-template'

const OrdersPage = () => {
  return (
    <AdminTemplate>
        <Orders />
    </AdminTemplate>
  )
}

export default OrdersPage