import React from "react";
import AdminTemplate from "../../templates/admin-template";
import OutdoorStore from "../../components/admin/outdoor-stores/outdoor-store";

const OutdoorStorePage = () => {
  return (
    <AdminTemplate>
      <OutdoorStore />
    </AdminTemplate>
  );
};

export default OutdoorStorePage;
