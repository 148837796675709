import axios from "axios";
import { settings } from "../helpers/settings";

const API_URL = settings.apiURL;
const username = "2ad33b44eea043598da1c96e002ab5c2";
const password = "3ea65ed4559f4db7a1c5a48ba62a0798";

// ssactivewear account
// const API_URL = "https://api.ssactivewear.com/v2/products/";
// const username = "664184";
// const password = "b7aba9e4-c917-4f0a-a713-2ca36b62b0c7";

export const getOrders = () => {
    // Kullanıcı adı ve şifreyi birleştirip base64 olarak kodlayın
    const basicAuth = btoa(`${username}:${password}`);

    // Axios ile GET isteğini yaparken "Authorization" başlığını ayarlayın
    return axios.get(`${API_URL}/orders`, {
        headers: {
            Authorization: `Basic ${basicAuth}`
        }
    });
};

export const getOrdersByPage = (
    page = 1,
    pageSize = 100,
    sortBy = "OrderDate",
    sortDir = "DESC",
    orderStatus = "shipped"
) => {
    // Kullanıcı adı ve şifreyi birleştirip base64 olarak kodlayın
    const basicAuth = btoa(`${username}:${password}`);

    // Axios ile GET isteğini yaparken "Authorization" başlığını ayarlayın
    return axios.get(`${API_URL}/orders?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&sortDir=${sortDir}&orderStatus=${orderStatus}`, {
        headers: {
            Authorization: `Basic ${basicAuth}`
        }
    });
};

export const getOrdersPHP = () => {
    return axios.get(`${API_URL}/getorders.php`);
};

// Tarihe göre orderları çağırır
export const getOrdersWithDatePHP = (formData) => {
    return axios.post(`${API_URL}/get_orders_with_date.php`, formData);
};

// export const getProducts = () => {
//     // Kullanıcı adı ve şifreyi birleştirip base64 olarak kodlayın
//     const basicAuth = btoa(`${username}:${password}`);

//     // Axios ile GET isteğini yaparken "Authorization" başlığını ayarlayın
//     return axios.get(`https://api.ssactivewear.com/v2/products/`, {
//         headers: {
//             Authorization: `Basic ${basicAuth}`
//         }
//     });
// };