import React from "react";
import AdminTemplate from "../../templates/admin-template";
import IndoorStore from "../../components/admin/indoor-stores/indoor-store";

const IndoorStorePage = () => {
  return (
    <AdminTemplate>
      <IndoorStore />
    </AdminTemplate>
  );
};

export default IndoorStorePage;
