import React from 'react'
import AdminTemplate from '../../templates/admin-template'
import OutdoorStores from '../../components/admin/outdoor-stores/outdoor-stores'

const OutdoorStoresPage = () => {
  return (
    <AdminTemplate>
        <OutdoorStores/>
    </AdminTemplate>
  )
}

export default OutdoorStoresPage