import React, { useState } from "react";
import { Container, Form, Button } from "react-bootstrap";
import "./sku-generator.scss";

const SkuGenerator = () => {
  const [result, setResult] = useState("");
  const productTypes = [
    { value: "Unisex Tshirt (UTS)", label: "Unisex Tshirt (UTS)" },
    { value: "Women Tshirt (WTS)", label: "Women Tshirt (WTS)" },
    { value: "Sweatshirt (USW)", label: "Sweatshirt (USW)" },
    { value: "Hoodie (UHD)", label: "Hoodie (UHD)" },
    { value: "Toddler (UTD)", label: "Toddler (UTD)" },
    { value: "Youth (UYT)", label: "Youth (UYT)" },
    { value: "Onesie (UOS)", label: "Onesie (UOS)" },
    { value: "Razerback(RZRBCK)", label: "Razerback(RZRBCK)" },
  ];

  const brands = [
    "Bella Canvas 3001",
    "Bella Canvas 3001CVC",
    "Bella Canvas Toddler 3001T",
    "Bella Canvas Youth 3001Y",
    "Bella Canvas Youth 3001Y CVC",
    "Bella Canvas Women 6400",
    "Bella Canvas Women 6400CVC",
    "Comfort Colors 1717",
    "Gildan Softstyle 64000",
    "Gildan Softstyle Women 64000L",
    "Gildan Unisex Soft-Style Youth 64000B",
    "Bella Canvas Women's Racerback Crop Tank 6682",
    "Next Level Women V Neck 1540",
    "Next Level Women Racerback Tank 1533",
    "Rabbit Skins Infant Baby Bodysuit 4400",
    "Gildan Crewneck 18000",
    "Gildan 18500",
    "Other",
  ];

  const colors = [
    "Sport Athletic Heater",
    "Peach Heather",
    "Dark Gray Heather",
    "Heather Mauve",
    "Heather Maroon",
    "Heather Midnight Navy",
    "Heather True Royal",
    "Berry",
    "Black",
    "Dark Gray",
    "Gray",
    "Navy",
    "Light Pink",
    "Red",
    "White",
    "Maroon",
    "Heather Deep Teal",
    "Kelly Green",
    "Mint",
    "Heather Grass Green",
    "Hot Pink",
    "Light Blue",
    "Military Green",
    "Raspberry",
    "Sand",
    "True Royal",
    "Heather Military Green",
    "Blue Jean",
    "Orchid",
    "Yam",
    "Pepper",
    "Moss",
    "Mustard",
    "Blossom",
    "Blue Spruce",
    "Brick",
    "Chambray",
    "Ivory",
    "Light Green",
    "Sage",
    "Sandstone",
    "Terracotta",
    "Violet",
    "Watermelon",
  ];

  const sizes = [
    "XS",
    "S",
    "M",
    "L",
    "XL",
    "2XL",
    "3XL",
    "2T",
    "3T",
    "4T",
    "5T",
    "3-6 M",
    "6-12 M",
    "12-18 M",
    "18-24 M",
  ];

  const shopNames = [
    "Abukka",
    "AlplanetArt",
    "BambinobelleBaby",
    "bambinocheride",
    "Buduu Fashion",
    "Christmas dtf",
    "dtc-customer-designs",
    "faire",
    "GiftShirtForMe",
    "LinaStarGift",
    "Mystiquebythesea",
    "NovemberRainy",
    "October Faster",
    "Rise of Mimi",
    "Softstyle Apparel",
    "Splendid",
    "Tutina Baby",
    "Haays Shop",
    "Alvia's Designs",
  ];

  function generateSKU() {
    const size = document.getElementById("size").value;
    const brand = document.getElementById("brand").value;
    const productType = document.getElementById("product-type").value;
    const color = document.getElementById("color").value;
    const shopName = document.getElementById("shop-name").value.substring(0, 5);
    const date = getCurrentDate();

    const sku = `${productType}-${getBrandCode(brand)}-${color}-${getSizeCode(
      size
    )}-${shopName}-${date}`;
    document.getElementById("result").textContent = `Oluşturulan SKU: ${sku}`;

    const copyButton = document.getElementById("copyButton");
    copyButton.style.display = "block";
    // Kopyala butonunu tıkladığında SKU'yu panoya kopyala.
    document
      .getElementById("copyButton")
      .addEventListener("click", function () {
        const sku = document.getElementById("result").textContent;
        const tempInput = document.createElement("input");
        tempInput.value = sku;
        document.body.appendChild(tempInput);
        tempInput.select();
        document.execCommand("copy");
        document.body.removeChild(tempInput);
        alert("SKU başarıyla kopyalandı: " + sku);
      });
  }

  function getCurrentDate() {
    const now = new Date();
    const day = String(now.getDate()).padStart(2, "0");
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const year = String(now.getFullYear());
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    const seconds = String(now.getSeconds()).padStart(2, "0");

    return `${day}${month}${year}-${hours}${minutes}${seconds}`;
  }

  function getBrandCode(brand) {
    switch (brand) {
      case "Bella Canvas 3001":
        return "BC3001";
      case "Bella Canvas 3001CVC":
        return "BC3001CVC";
      case "Bella Canvas Toddler 3001T":
        return "BC3001T";
      case "Bella Canvas Youth 3001Y":
        return "BC3001Y";
      case "Bella Canvas Youth 3001Y CVC":
        return "BC3001YCVC";
      case "Bella Canvas Women 6400":
        return "BC6400";
      case "Bella Canvas Women 6400CVC":
        return "BC6400CVC";
      case "Comfort Colors 1717":
        return "CC1717";
      case "Gildan Softstyle 64000":
        return "GS64000";
      case "Gildan Softstyle Women 64000L":
        return "GS64000L";
      case "Gildan Unisex Soft-Style Youth 64000B":
        return "GS64000B";
      case "Bella Canvas Women's Racerback Crop Tank 6682":
        return "BC6682";
      case "Next Level Women V Neck 1540":
        return "NL1540";
      case "Next Level Women Racerback Tank 1533":
        return "NL1533";
      case "Rabbit Skins Infant Baby Bodysuit 4400":
        return "RS4400";
      case "Gildan Crewneck 18000":
        return "GC18000";
      case "Gildan 18500":
        return "G18500";
      case "Other":
        return "Other";
      default:
        return "N/A";
    }
  }

  function getSizeCode(size) {
    switch (size) {
      case "XS":
        return "XS";
      case "S":
        return "S";
      case "M":
        return "M";
      case "L":
        return "L";
      case "XL":
        return "XL";
      case "2XL":
        return "2XL";
      case "3XL":
        return "3XL";
      case "2T":
        return "2T";
      case "3T":
        return "3T";
      case "4T":
        return "4T";
      case "5T":
        return "5T";
      case "3-6 M":
        return "3-6M";
      case "12-18 M":
        return "12-18M";
      case "18-24M":
        return "18-24M";
      default:
        return "N/A";
    }
  }

  function updateBrandOptions() {
    const productType = document.getElementById("product-type").value;
    const brandSelect = document.getElementById("brand");
    if (productType === "TS") {
      brandSelect.innerHTML = "";
      const validBrands = [
        "Bella Canvas 3001",
        "Comfort Colors 1717",
        "Gildan Softstyle 64000",
        "Bella Canvas 3001CVC",
        "Bella Canvas Toddler 3001T",
        "Bella Canvas Youth 3001Y",
        "Bella Canvas Youth 3001Y CVC",
        "Bella Canvas Women 6400",
        "Bella Canvas Women 6400CVC",
        "Next Level Women V Neck 1540",
        "Gildan Softstyle Women 64000L",
        "Gildan Softstyle 64000",
      ];
      validBrands.forEach((brand) => {
        const option = document.createElement("option");
        option.value = brand;
        option.textContent = brand;
        brandSelect.appendChild(option);
      });
    } else if (productType === "SW") {
      brandSelect.innerHTML = "";
      const validBrands = ["Gildan Crewneck 18000"];
      validBrands.forEach((brand) => {
        const option = document.createElement("option");
        option.value = brand;
        option.textContent = brand;
        brandSelect.appendChild(option);
      });
    } else if (productType === "HD") {
      brandSelect.innerHTML = "";
      const validBrands = ["Gildan Crewneck 18500"];
      validBrands.forEach((brand) => {
        const option = document.createElement("option");
        option.value = brand;
        option.textContent = brand;
        brandSelect.appendChild(option);
      });
    } else if (productType === "OS") {
      brandSelect.innerHTML = "";
      const validBrands = ["Rabbit Skins 4400"];
      validBrands.forEach((brand) => {
        const option = document.createElement("option");
        option.value = brand;
        option.textContent = brand;
        brandSelect.appendChild(option);
      });
    } else if (productType === "RZRBCK") {
      brandSelect.innerHTML = "";
      const validBrands = [
        "Next Level Women Racerback Tank 1533",
        "Bella Canvas Women's Racerback Crop Tank 6682",
      ];
      validBrands.forEach((brand) => {
        const option = document.createElement("option");
        option.value = brand;
        option.textContent = brand;
        brandSelect.appendChild(option);
      });
    } else {
      brandSelect.innerHTML = "";
      const allBrands = [
        "Bella Canvas 3001",
        "Bella Canvas 3001CVC",
        "Bella Canvas Toddler 3001T",
        "Bella Canvas Youth 3001Y",
        "Bella Canvas Youth 3001Y CVC",
        "Bella Canvas 6400",
        "Bella Canvas Women 6400CVC",
        "Comfort Colors 1717",
        "Gildan Softstyle 64000",
        "Gildan Softstyle Women 64000L",
        "Gildan Unisex Soft-Style Youth 64000B",
        "Bella Canvas Women's Racerback Crop Tank 6682",
        "Next Level Women V Neck 1540",
        "Next Level Women Racerback Tank 1533",
        "Rabbit Skins Infant Baby Bodysuit 4400",
        "Gildan Crewneck 18000",
        "Gildan 18500",
      ];
      allBrands.forEach((brand) => {
        const option = document.createElement("option");
        option.value = brand;
        option.textContent = brand;
        brandSelect.appendChild(option);
      });
    }
  }

  window.onload = function () {
    updateBrandOptions();
  };

  return (
    <Container fluid className="sku-container">
      <Form className="p-4">
        <h2>SKU Generator</h2>
        <Form.Group controlId="product-type" className="mb-3 mt-5">
          <Form.Select onChange={updateBrandOptions}>
            <option value="">Select Product Type</option>
            {productTypes.map((productType) => (
              <option key={productType.value} value={productType.value}>
                {productType.label}
              </option>
            ))}
          </Form.Select>
        </Form.Group>

        <Form.Group controlId="brand" className="mb-3">
          <Form.Select>
            <option value="">Select Brand</option>
            {brands.map((brand) => (
              <option key={brand} value={brand}>
                {brand}
              </option>
            ))}
          </Form.Select>
        </Form.Group>

        <Form.Group controlId="color" className="mb-3">
          <Form.Select>
            <option value="">Select Color</option>
            {colors.map((color) => (
              <option key={color} value={color}>
                {color}
              </option>
            ))}
          </Form.Select>
        </Form.Group>

        <Form.Group controlId="size" className="mb-3">
          <Form.Select>
            <option value="">Select Size</option>
            {sizes.map((size) => (
              <option key={size} value={size}>
                {size}
              </option>
            ))}
          </Form.Select>
        </Form.Group>

        <Form.Group controlId="shop-name" className="mb-3">
          <Form.Select>
            <option value="">Select Shop Name</option>
            {shopNames.map((shopName) => (
              <option key={shopName} value={shopName}>
                {shopName}
              </option>
            ))}
          </Form.Select>
        </Form.Group>

        <Button variant="primary" onClick={generateSKU} className="my-3">Generate</Button>
        <div className="resultDiv">
        <p id="result">{result}</p>
        <Button variant="primary" id="copyButton" className="my-3" style={{ display: "none" }}>
          Copy SKU
        </Button>
        </div>
      </Form>
    </Container>
  );
};

export default SkuGenerator;
