import React from 'react'
import AdminTemplate from '../../templates/admin-template'
import Stores from '../../components/admin/stores/stores'

const StoresPage = () => {
  return (
    <AdminTemplate>
        <Stores />
    </AdminTemplate>
  )
}

export default StoresPage