import React from 'react'
import AdminTemplate from '../../templates/admin-template'
import Profile from '../../components/common/profile/profile'

const ProfilePage = () => {
  return (
    <AdminTemplate>
        <Profile />
    </AdminTemplate>
  )
}

export default ProfilePage